<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">
            
			<!-- Authors Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Authors Table Card -->
				<CardAuthorTable
					:data="table1Data"
					:columns="table1Columns"
					@updateMode="update($event)"									
				></CardAuthorTable>
				<!-- / Authors Table Card -->

			</a-col>
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->
		
	</div>
</template>

<script>

	// "Authors" table component.
	import CardAuthorTable from '../components/Cards/CardAuthorTable' ;
	const axios = require('axios');

	
	// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'HOSTNAME',
			dataIndex: 'server',
			scopedSlots: { customRender: 'server' },
		},
		{
			title: 'COUNTRY',
			dataIndex: 'location',
			scopedSlots: { customRender: 'location' },
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
		},
		{
			title: 'CPU %',
			dataIndex: 'cpu_values',
			scopedSlots: { customRender: 'cpu_values' },
		},		
		{
			title: 'LAST_UPDATE',
			dataIndex: 'updatedAt',
			scopedSlots: { customRender: 'updatedAt' },
		},		
	];

	// "Authors" table list of rows and their properties.
	const table1Data = [];


	export default ({
		components: {
			CardAuthorTable
		},
		data() {
			return {
				// Associating "Authors" table data with its corresponding property.
				table1Data: table1Data,

				// Associating "Authors" table columns with its corresponding property.
				table1Columns: table1Columns,

			}
		},
		mounted(){
         this.getServersStatus('all');
		},
		methods:{
		getServersStatus(mode){
                    /*let part = mode.split('@-@')
                    let filter = ""
                    if(part>1) {
                       if(part[1]){
                         mode = "filter"
                         filter = part[1]
                       }
                      else{
                       mode = part[0]
                      }
                    }*/
		    let url = ""
		    if (mode == "all")
               url = "https://theserverdashboard.info:7200/api/vpnserver/v1/list"
            else if (mode == "online")
               url = "https://theserverdashboard.info:7200/api/vpnserver/v1/serversup"
            else if  (mode == "offline")
               url = "https://theserverdashboard.info:7200/api/vpnserver/v1/serversdown"   
            else if  (mode == "lastchanged")
               url = "https://theserverdashboard.info:7200/api/vpnserver/v1/latest-changed"   
            else if (mode == "filter")
               url = "https://theserverdashboard.info:7200/api/vpnserver/v1/hostname/"+filter 

  	      axios.get(url)
	      .then((response) => {
	        this.table1Data = []
	        if(response.data.data.rows) {
	          this.servers = response.data.data.rows 
	          this.total =  response.data.data.count
	        }
	        else {
	          this.servers = response.data.data
	          this.total =  response.data.count
	        }

	        	        
            let serv = {}
	        for(let i in this.servers){
		          serv =  {
					key: i,					
					server: {
					  ipaddress:this.servers[i].ipaddress,
					  hostname:this.servers[i].hostname,
					},
					location: {
                      country: this.servers[i].country,
                      city: this.servers[i].city,
					},
					cpu_values: {
                      cpu: this.servers[i].cpu,                      
					},
					rx_values: {
                      rx: this.servers[i].rx,                     
					},  
					tx_values: {                      
                      tx: this.servers[i].tx,
					},  
					status: this.servers[i].status,
					updatedAt: this.servers[i].updatedAt
			     }	  

			     this.table1Data.push(serv)       
	        }	        


	        console.log(this.table1Data)
	      })

		},
		update(event){
                   /*let value= ""
                   if(event.split('@-@').length>1){
                      let part = event.split('@-@')
                     if(part[1])
                       value = event
                     else
                       value = part[0]
                   }
                   else
                      value = event.target.value
		   console.log(value)*/
		   this.getServersStatus(event.target.value)
		},
		updateFilter( filter ) {
		console.log("$$$$$$$$$")
		console.log(filter.target.value) 
		this.getServersStatus(filter.target.value)
		},
                },
	})

</script>

<style lang="scss">
</style>
