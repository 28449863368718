<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">VPN Servers Status</h5>
				</a-col>

				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="authorsHeaderBtns" size="small">
						<a-radio-button value="all" @click="update">ALL</a-radio-button>
						<a-radio-button value="online" @click="update">ONLINE</a-radio-button>
						<a-radio-button value="offline" @click="update">OFFLINE</a-radio-button>
						<a-radio-button value="lastchanged" @click="update">LAST CHANGED</a-radio-button>
					</a-radio-group>
				</a-col>

                <!--<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
				<a-input-search v-model="search_string" class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="server search…" @change="toogleSearching" :loading='searchLoading'>-->
						
			     </a-input-search>
                </a-col>		    

			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">

			<template slot="server" slot-scope="server">
				<div class="table-avatar-info">
					<div class="avatar-info">
						<h6>{{ server.hostname }}</h6>						
					</div>
				</div>
			</template>

			<template slot="location" slot-scope="location">
				<div class="table-avatar-info">
				   <div class="avatar-info">
					  <h6>{{ location.country }}</h6>
					  <p>{{ location.city }}</p>
				   </div>	  
				</div>
			</template>


            <template slot="cpu_values" slot-scope="cpu_values">
				<a-tag class="tag-status ant-tag-success" v-if="cpu_values.cpu <= 25">
					{{ cpu_values.cpu}}
				</a-tag>
				<a-tag class="tag-status ant-tag-warning" v-if="cpu_values.cpu > 25 && cpu_values.cpu < 50">
					{{ cpu_values.cpu}}
				</a-tag>
				<a-tag class="tag-status ant-tag-danger" v-if="cpu_values.cpu > 50">
					{{ cpu_values.cpu}}
				</a-tag>
			</template>


            <template slot="status" slot-scope="status">
				<a-tag class="tag-status" :class="status ? 'ant-tag-success' : 'ant-tag-danger'">
					{{ status ? "ONLINE" : "OFFLINE" }}
				</a-tag>
			</template>

			<template slot="updatedAt" slot-scope="updatedAt">
				<div class="table-avatar-info">
				   <div class="avatar-info">
					  <h6>{{ updatedAt }}</h6>					  
				   </div>	  
				</div>
			</template>


		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
                                mode: 'all',
			}
		},
		methods: {
	        update(value)
	        {
	            console.log(value.target.value)
                    this.mode = value.target.value
	            this.$emit("updateMode",value)
	        },
                toogleSearching(filter){
	        console.log("Filter1:"+filter.target.value)
                //this.$emit("updateMode", this.mode+'@-@'+filter.target.value)
                }              
        },
	})

</script>
